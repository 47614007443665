import { format } from "@/util/date-util";
import * as QUOTE_TYPE from "@/constants/quote-types.constants.js";
import { getRouterLink } from "@/util/helpers";
import { setNoteFromRequest } from "@/models/Note";
import { TAX_OPTION } from "@/data/case-data";

export const TAX_OPTIONS = [
  TAX_OPTION._401K,
  TAX_OPTION._403B,
  TAX_OPTION.INHERITED_IRA_PLAN,
  TAX_OPTION.IRA,
  TAX_OPTION.NON_QUALIFIED,
  TAX_OPTION.PENSION_PLAN,
  TAX_OPTION.PROFIT_SHARING_PLAN,
  TAX_OPTION.ROTH_IRA,
  TAX_OPTION.SEP_IRA,
  TAX_OPTION.SIMPLE_IRA
];

export const STATUSES = [
  "Entered",
  "Pending",
  "Awaiting More Information from Agent",
  "Refer to Advanced Planning",
  "Refer to Marketing Manager",
  "Refer to Internal Wholesaler",
  "Awaiting Application",
  "Received Application",
  "No Sale",
  "Awaiting Policy Review",
  "Policy Review Complete"
];

export const MODES = ["Annual", "Semi-Annual", "Quarterly", "Monthly"];

export const TERM_SUBTYPES = [
  { value: "10 Year Term", title: "10 Year" },
  { value: "15 Year Term", title: "15 Year" },
  { value: "20 Year Term", title: "20 Year" },
  { value: "25 Year Term", title: "25 Year" },
  { value: "30 Year Term", title: "30 Year" },
  { value: "35 Year Term", title: "35 Year" },
  { value: "40 Year Term", title: "40 Year" }
];

export const GUL_SUBTYPES = [
  { value: "GUL to Age 90", title: "To Age 90" },
  { value: "GUL to Age 95", title: "To Age 95" },
  { value: "GUL to Age 100", title: "To Age 100" },
  { value: "GUL to Age 105", title: "To Age 105" },
  { value: "GUL to Age 120", title: "To Age 120" }
];

export const QA_LINES = [QUOTE_TYPE.LIFE, QUOTE_TYPE.LTC];
export const QA_UNDERWRITING_CLASSES = [
  "Standard Plus",
  "Preferred NT",
  "Super Preferred",
  "Standard NT",
  "Preferred Tobacco",
  "Standard Tobacco",
  "Level",
  "Graded",
  "Level Tobacco",
  "Declined"
];

export const HELPER_TYPES = {
  TERM: "_TERM",
  GUL: "_GUL"
};

const SURRENDER_PERIODS = [];
for (let i = 0; i < 10; i++) {
  let title = `${i + 1} Years`;
  if (i === 0) title = `${i + 1} Year`;
  SURRENDER_PERIODS.push({ value: i + 1, title });
}
export { SURRENDER_PERIODS };

export const INCOME_DURATIONS = {
  LIFE: "Life",
  CERTAIN_ONLY: "Certain Only"
};
export const INCOME_DURATION_ITEMS = Object.values(INCOME_DURATIONS);
export const REFUND_OPTIONS = {
  LIFE_ONLY: "Life Only",
  CERTAIN_PERIOD: "Certain Period",
  LIFE_WITH_INSTALLMENT_REFUND: "Installment Refund",
  LIFE_WITH_CASH_REFUND: "Cash Refund"
};
export const REFUND_OPTION_ITEMS = Object.values(REFUND_OPTIONS);

export const generatePayDurations = currentAge => {
  const payDurations = [{ title: "Life", value: -1 }];
  for (let i = currentAge; i <= 120; i++) {
    let delta = i - currentAge;
    let title = `${delta} Years / Age ${i}`;
    if (delta === 1) title = `${delta} Year / Age ${i}`;
    payDurations.push({ title, value: delta });
  }
  return payDurations;
};

export const QUOTE_ROLES = {
  ANNUITANT: "Annuitant",
  JOINT_ANNUITANT: "Joint Annuitant",
  INSURED: "Insured",
  JOINT_INSURED: "Joint Insured"
};

export function QuoteRequest(model = {}) {
  return {
    agent: model?.agent || null,
    assignable: model?.assignable || { id: null, type: null, name: null },
    approvedDomainUrl: model?.approvedDomainUrl || null,
    benefitPeriod: model?.benefitPeriod || null,
    case: model?.case || null,
    concept: model?.concept || null,
    createdAt: model?.createdAt || null,
    description: model?.description || null,
    eapp: model?.eapp || null,
    eliminationPeriod: model?.eliminationPeriod || null,
    existingBenefitMaximum: model?.existingBenefitMaximum || null,
    existingBenefitPeriod: model?.existingBenefitPeriod || null,
    existingCoverage: model?.existingCoverage || null,
    existingEliminationPeriod: model?.existingEliminationPeriod || null,
    existingEmployerPaid: model?.existingEmployerPaid || null,
    existingPercentageMaximum: model?.existingPercentageMaximum || null,
    files: model?.files || [],
    followUp: model?.followUp || null,
    id: model?.id || null,
    businessOwner: model?.businessOwner || null,
    inflation: model?.inflation || null,
    discount: model?.discount || null,
    insured: model?.insured || new QuoteRole(),
    jointInsured: model?.jointInsured || new QuoteRole(),
    line: model?.line || "life",
    monthlyBenefit: model?.monthlyBenefit || null,
    monthlyBenefitSolve: model?.monthlyBenefitSolve || null,
    notes: model?.notes || [],
    opportunities: model?.opportunities || [],
    productType: model?.productType || [],
    qualifiedPlanBalance: model?.qualifiedPlanBalance || null,
    quoteRoles: model?.quoteRoles || [],
    riders: model?.riders || null,
    smoker: model?.smoker || null,
    surrenderPeriods: model?.surrenderPeriods || [],
    typeOfFunds: model?.typeOfFunds || null,

    state: model?.state,
    mode: model?.mode,
    faceAmount: model?.faceAmount,
    premium: model?.premium,
    status: model?.status,

    payDurations: model?.payDurations || [],
    incomeEnabled: model?.incomeEnabled || false,
    incomeStartAge: model?.incomeStartAge || null,
    incomeEndAge: model?.incomeEndAge || null,

    incomeDurationType: model?.incomeDurationType || null,
    annuityGuaranteeType: model?.annuityGuaranteeType || null,
    certainPeriod: model?.certainPeriod || null,
    incomeStartDate: model?.incomeStartDate || null
  };
}

export function Quote(model = {}) {
  return {
    agent: model?.agent || null,
    annuityGuaranteeType: model?.annuityGuaranteeType || null,
    approvedDomainUrl: model?.approvedDomainUrl || null,
    assignable: model?.assignable || { id: null, type: null, name: null },
    benefitPeriod: model?.benefitPeriod || null,
    businessOwner: model?.businessOwner || null,
    case: model?.case || null,
    certainPeriod: model?.certainPeriod || null,
    concept: model?.concept || null,
    createdAt: model?.createdAt || null,
    description: model?.description || null,
    discount: model?.discount || null,
    eapp: model?.eapp || null,
    eliminationPeriod: model?.eliminationPeriod || null,
    existingBenefitMaximum: model?.existingBenefitMaximum || null,
    existingBenefitPeriod: model?.existingBenefitPeriod || null,
    existingCoverage: model?.existingCoverage || null,
    existingEliminationPeriod: model?.existingEliminationPeriod || null,
    existingEmployerPaid: model?.existingEmployerPaid || null,
    existingPercentageMaximum: model?.existingPercentageMaximum || null,
    faceAmount: model?.faceAmount || null,
    followUp: model?.followUp || null,
    id: model?.id || null,
    incomeDurationType: model?.incomeDurationType || null,
    incomeEnabled: model?.incomeEnabled || false,
    incomeEndAge: model?.incomeEndAge || null,
    incomeStartAge: model?.incomeStartAge || null,
    incomeStartDate: model?.incomeStartDate || null,
    inflation: model?.inflation || null,
    insured: model?.insured || new QuoteRole(),
    jointInsured: model?.jointInsured || new QuoteRole(),
    line: model?.line || "life",
    mode: model?.mode || null,
    monthlyBenefit: model?.monthlyBenefit || null,
    monthlyBenefitSolve: model?.monthlyBenefitSolve || null,
    notes: model?.notes || [],
    opportunities: model?.opportunities || [],
    payDurations: model?.payDurations || [],
    premium: model?.premium || null,
    productType: model?.productType || [],
    qualifiedPlanBalance: model?.qualifiedPlanBalance || null,
    quoteRoles: model?.quoteRoles || [],
    riders: model?.riders || null,
    smoker: model?.smoker || null,
    state: model?.state || null,
    status: model?.status || null,
    surrenderPeriods: model?.surrenderPeriods || [],
    termDuration: model?.termDuration || null,
    typeOfFunds: model?.typeOfFunds || null
  };
}

export function setQuoteFromRequest(model = {}) {
  const quote = new Quote();
  quote.annuityGuaranteeType = model?.annuity_guarantee_type;
  quote.approvedDomainUrl = model?.approved_domain_url;
  quote.benefitPeriod = model?.benefit_period;
  quote.businessOwner = model?.business_owner;
  quote.case = model?.case?.id;
  quote.certainPeriod = model?.certain_period;
  quote.concept = model?.concept;
  quote.createdAt = model?.created_at;
  quote.description = model?.description;
  quote.discount = model?.discount;
  quote.eapp = model?.electronic_application?.id;
  quote.eliminationPeriod = model?.elimination_period;
  quote.existingBenefitMaximum = model?.existing_benefit_maximum;
  quote.existingBenefitPeriod = model?.existing_benefit_period;
  quote.existingCoverage = model?.existing_coverage;
  quote.existingEliminationPeriod = model?.existing_elimination_period;
  quote.existingEmployerPaid = model?.existing_employer_paid;
  quote.existingPercentageMaximum = model?.existing_percentage_maximum;
  quote.faceAmount = model?.face_amount;
  quote.id = model?.id;
  quote.incomeDurationType = model?.income_duration_type;
  quote.incomeEndAge = model?.income_end_age;
  quote.incomeStartAge = model?.income_start_age;
  quote.incomeStartDate = model?.income_start_date;
  quote.inflation = model?.inflation;
  quote.insured = model?.insured?.name;
  quote.line = model?.line;
  quote.mode = model?.mode;
  quote.monthlyBenefit = model?.monthly_benefit;
  quote.monthlyBenefitSolve = model?.monthly_benefit_type;
  quote.premium = model?.premium;
  quote.qualifiedPlanBalance = model?.qualified_plan_balance;
  quote.riders = model?.riders;
  quote.state = model?.state;
  quote.status = model?.status;
  quote.termDuration = model?.term_duration;
  quote.typeOfFunds = model?.type_of_funds;

  if (
    quote.incomeStartAge ||
    quote.incomeStartAge === 0 ||
    quote.incomeEndAge ||
    quote.incomeEndAge === 0
  ) {
    quote.incomeEnabled = true;
  }
  let productType = model?.product_type;

  if (typeof productType === "string") {
    try {
      productType = JSON.parse(productType);
      quote.productType = productType;
      const termValues = TERM_SUBTYPES.map(v => v.value);
      if (productType.some(v => termValues.includes(v))) {
        productType.push(HELPER_TYPES.TERM);
      }

      const gulValues = GUL_SUBTYPES.map(v => v.value);
      if (productType.some(v => gulValues.includes(v))) {
        productType.push(HELPER_TYPES.GUL);
      }
    } catch (e) {
      // do nothing
    }
  }

  let surrenderPeriods = model?.surrender_periods;

  if (typeof surrenderPeriods === "string") {
    try {
      surrenderPeriods = JSON.parse(surrenderPeriods);
      quote.surrenderPeriods = surrenderPeriods;
    } catch (e) {
      // do nothing
    }
  }

  let payDurations = model?.pay_durations;

  if (typeof payDurations === "string") {
    try {
      payDurations = JSON.parse(payDurations);
      quote.payDurations = payDurations;
    } catch (e) {
      // do nothing
    }
  }

  if (model?.follow_up_at) {
    try {
      quote.followUp = format(new Date(model.follow_up_at), "yyyy-MM-dd");
    } catch (e) {
      // do something?
    }
  }

  if (model?.assignable) {
    quote.assignable = {
      id: model.assignable.id,
      name: model.assignable.name,
      type: model.assignable.type.model
    };
  }

  quote.notes = model?.notes?.map(setNoteFromRequest);

  quote.opportunities = model?.opportunities?.map(
    setQuoteRoomAccessFromRequest
  );

  quote.quoteRoles = model?.quote_roles?.map(setQuoteRoleFromRequest);

  return quote;
}

function QuoteRole(model = {}) {
  return {
    id: model?.id || null,
    ownable: {
      id: model?.ownable?.id || null,
      name: model?.ownable?.name || null,
      firstName: model?.ownable?.firstName || null,
      lastName: model?.ownable?.lastName || null,
      gender: model?.ownable?.gender || null,
      birthdate: model?.ownable?.birthdate || null,
      maritalStatus: model?.ownable?.maritalStatus || null,
      income:
        model?.ownable?.income || model?.ownable?.income === 0
          ? model?.ownable?.income
          : null,
      tobacco: Boolean(model?.ownable?.tobacco)
    },
    occupationAndDuties: null,
    role: model?.role || null,
    underwritingClass: model?.underwritingClass || null,
    qualifiedPlanBalance: model?.qualifiedPlanBalance || null
  };
}

function setQuoteRoleFromRequest(model = {}) {
  const quoteRole = QuoteRole();

  quoteRole.id = model?.id;
  quoteRole.role = model?.role;
  quoteRole.underwritingClass = model?.underwriting_class;
  quoteRole.qualifiedPlanBalance = model?.qualified_plan_balance;

  quoteRole.ownable.type = model?.ownable?.type;
  quoteRole.ownable.id = model?.ownable?.id;
  quoteRole.ownable.name = model?.ownable?.name;
  quoteRole.ownable.firstName = model?.ownable?.first_name;
  quoteRole.ownable.lastName = model?.ownable?.last_name;
  quoteRole.ownable.gender = model?.ownable?.gender;
  quoteRole.ownable.birthdate = model?.ownable?.birthdate;
  quoteRole.ownable.maritalStatus = model?.ownable?.marital_status;
  quoteRole.ownable.income = model?.ownable?.income;

  return quoteRole;
}

export function QuoteRoomAccess(model = {}) {
  return {
    agent: model?.agent || null,
    disabled: model?.disabled || null,
    email: model?.email || null,
    id: model?.id || null,
    owner: model?.owner || null,
    name: model?.name || "",
    ownerId: model?.ownerId || null,
    ownerType: model?.ownerType || null,
    routerLink: model?.routerLink || null
  };
}

export function setQuoteRoomAccessFromRequest(model = {}) {
  const access = QuoteRoomAccess();
  access.agent = model?.agent;
  access.disabled = model?.disabled;
  access.email = model?.email;
  access.id = model?.id;
  access.owner = model?.owner;
  access.ownerId = model?.ownable?.id;
  access.name = model?.ownable?.name;
  access.ownerType = model?.ownable?.type?.model;

  if (access.ownerId && access.ownerType) {
    access.routerLink = getRouterLink(access.ownerType, access.ownerId);
  }

  return access;
}

export function QuoteAttributeToRequest(model, attribute) {
  const requestMap = {
    monthlyBenefitSolve: "monthly_benefit_solve",
    monthlyBenefit: "monthly_benefit",
    riders: "riders",
    existingBenefitMaximum: "existing_benefit_maximum",
    existingBenefitPeriod: "existing_benefit_period",
    existingCoverage: "existing_coverage",
    existingEliminationPeriod: "existing_elimination_period",
    existingEmployerPaid: "existing_employer_paid",
    existingPercentageMaximum: "existing_percentage_maximum",
    benefitPeriod: "benefit_period",
    eliminationPeriod: "elimination_period",
    mode: "mode",
    smoker: "smoker",
    status: "status",
    termDuration: "term_duration",
    state: "state",
    faceAmount: "face_amount",
    premium: "premium",
    description: "description",
    inflation: "inflation",
    discount: "discount",
    files: "documents_attributes",
    typeOfFunds: "type_of_funds",
    productType: "product_type",
    followUp: "follow_up_at",
    assignable: "assignable",

    surrenderPeriods: "surrender_periods",
    incomeDurationType: "income_duration_type",
    annuityGuaranteeType: "annuity_guarantee_type",
    certainPeriod: "certain_period",
    incomeStartDate: "income_start_date",
    incomeStartAge: "income_start_age",
    incomeEndAge: "income_end_age",
    payDurations: "pay_durations",
    businessOwner: "business_owner"
  };

  let value = model[attribute];
  if (attribute === "assignable") {
    value = {
      assignable_type: model.assignable?.type || null,
      assignable_id: model.assignable?.id || null
    };
  } else if (["payDurations", "surrenderPeriods"].includes(attribute)) {
    value = JSON.stringify(value);
  } else if (attribute === "productType") {
    value = productTypeToRequestValue(value);
  }
  return { [requestMap[attribute]]: value };
}

export function productTypeToRequestValue(productType) {
  const helperTypes = Object.values(HELPER_TYPES);
  const value = productType.filter(v => !helperTypes.includes(v));
  return JSON.stringify(value);
}

export function QuoteRequestAnnuityInfo(model = {}) {
  return {
    typeOfFunds: model.typeOfFunds,
    productType: model.productType,
    premium: model.premium,
    state: model.state,
    status: model.status,
    surrenderPeriods: model.surrenderPeriods,
    incomeDurationType: model.incomeDurationType,
    incomeStartAge: model.incomeStartAge,
    incomeEndAge: model.incomeEndAge,
    annuityGuaranteeType: model.annuityGuaranteeType,
    certainPeriod: model.certainPeriod,
    incomeStartDate: model.incomeStartDate
  };
}

export function QuoteRequestDisabilityInfo(model = {}) {
  return {
    riders: model.riders,
    existingCoverage: model.existingCoverage,
    businessOwner: model.businessOwner,
    existingBenefitMaximum: model.existingBenefitMaximum,
    existingBenefitPeriod: model.existingBenefitPeriod,
    existingEliminationPeriod: model.existingEliminationPeriod,
    existingEmployerPaid: model.existingEmployerPaid,
    existingPercentageMaximum: model.existingPercentageMaximum,
    monthlyBenefit: model.monthlyBenefit,
    monthlyBenefitSolve: model.monthlyBenefitSolve,
    mode: model.mode,
    benefitPeriod: model.benefitPeriod,
    eliminationPeriod: model.eliminationPeriod,
    productType: model.productType,
    smoker: model.smoker,
    status: model.status,
    state: model.state
  };
}

export function QuoteRequestLifeInfo(model = {}) {
  return {
    faceAmount: model.faceAmount,
    incomeEnabled: model.incomeEnabled,
    incomeEndAge: model.incomeEndAge,
    incomeStartAge: model.incomeStartAge,
    mode: model.mode,
    payDurations: model.payDurations,
    premium: model.premium,
    productType: model.productType,
    state: model.state,
    status: model.status
  };
}

export function QuoteRequestLtcInfo(model = {}) {
  return {
    state: model.state,
    mode: model.mode,
    monthlyBenefit: model.monthlyBenefit,
    benefitPeriod: model.benefitPeriod,
    eliminationPeriod: model.eliminationPeriod,
    inflation: model.inflation,
    discount: model.discount,
    status: model.status
  };
}

export function QuoteRequestProductTypeData(model = {}) {
  const numOrNull = v => (v || v === 0 ? v : null);
  return {
    enabled: model?.enabled || false,
    productTypes: model?.productTypes || [],
    payDurations: model?.payDurations || [],
    incomeEnabled: model?.incomeEnabled || false,
    incomeStartAge: numOrNull(model?.incomeStartAge),
    incomeEndAge: numOrNull(model?.incomeEndAge),
    incomeDurationType: numOrNull(model?.incomeDurationType),
    incomeStartDate: model?.incomeStartDate || null,
    annuityGuaranteeType: model?.annuityGuaranteeType || null,
    certainPeriod: model?.certainPeriod || null,
    surrenderPeriods: model?.surrenderPeriods || [],
    soleIncomeStartAge: numOrNull(model?.soleIncomeStartAge)
  };
}
